<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{on, attrs}">
      <v-btn v-on="on" v-bind="attrs" color="yellow">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Modifier le champ</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
            <v-select class="mx-2" form="selectForm" @change="selectModel.field = null"
                        :rules="[value => value !== null || 'Ne doit pas être vide']" :items="source_select"
                        item-text="name" item-value="value" v-model="selectModel.source"></v-select>
              <v-select class="mx-2" form="selectForm" :rules="[value => value !== null || 'Ne doit pas être vide']"
                        :items="fields_select" item-text="name" item-value="value"
                        v-model="selectModel.field"></v-select>
              <v-text-field class="mx-2" form="selectForm" :rules="[value => value !== null && value !== '' || 'Ne doit pas être vide']"
                            v-model="selectModel.value"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn  @click="updateField" :disabled="loading" color="primary">Modifier</v-btn>
        <v-progress-circular color="primary" indeterminate v-if="loading"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import {convertToSelect} from "@/util";
import {SelectRepository} from "@/repository/select";

const SOURCES = {
  "bien_immo": "Bien immobilier",
  "beneficiaire": "Bénéficiaire",
  "financement": "Financement",
  "document": "Document",
  "dossier": "Dossier",
  "entreprise": "Entreprise",
}

const FIELDS = {
  "beneficiaire": {
    "titre": "Titre",
    "situation_matrimoniale": "Situation matrimonial",
  },
  "dossier": {
    "attachments_types": "Types de pièces jointes",
    "statut_pret": "Statut de prêt",
    "enqueteurs": "Enquêteurs",
    "controleurs_deal": "Contrôleurs DEAL",
  },
  "bien_immo": {
    "type_bien": "Type de bien",
    "annee_construction": "Année construction",
    "nbre_pieces": "Nombre Pièces",
    "nbre_etage": "Nombre étage",
    "type_assainissement": "Type Assainissement",
  },
  "financement": {
    "origine_financement": "Origine de financement",
    "origine_emploi": "Origine de l'emploi",
    "origine_plafond": "Origine du plafond",
  },
  "document": {
    "signe_par": "Signé par",
  },
  "entreprise": {
    "entreprise_attachment": "Document de l'entreprise",
  }
}
export default {
  name: 'EditSelect',
  mounted() {
    this.selectModel = JSON.parse(JSON.stringify(this.select));
  },
  data() {
    return {
      loading: false,
      dialog: false,
      selectModel: {
        "source": "",
        "field": "",
        "value": ""
      }
    };
  },
  props: {
    select: Object,
  },
  methods: {
    async updateField() {
      this.loading = true;
      let repository = new SelectRepository();
      let response = await repository.update(this.selectModel);
      if(response) {
        this.$store.dispatch("annonce/annonceSuccess", "Champ modifié !");
      }
      this.loading = false;
      this.dialog = false;
      this.$emit("reload");
    }
  },
  computed: {
    source_select() {
      return convertToSelect(SOURCES);
    },
    fields_select() {
      return this.selectModel.source !== null ? convertToSelect(FIELDS[this.selectModel.source]) : [];
    },
    all_fields() {
      return {...FIELDS["beneficiaire"], ...FIELDS["bien_immo"], ...FIELDS["financement"], ...FIELDS["document"],
      ...FIELDS["dossier"], ...FIELDS["entreprise"]};
    },
  }
}
</script>